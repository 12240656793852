import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import * as R from 'remeda';
import { differenceInCalendarDays, format, isAfter, subYears } from 'date-fns';
import PageHeader from '../../../components/PageHeader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from '../../../redux/store';
import { selectCorporateView, selectCurrentCustomerNumber, selectPracticeDetails, selectTeamMembers } from '../../../redux/customer';
import { getFullNameOrEmail, selectCurrentUser, selectIsAdminOrOwner } from '../../../redux/user';
import constants from '../../../constants';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Autocomplete, Box, Button, FormControl, Snackbar, Stack, Tab, Table, TableBody, TableHead, TableRow, Tabs, TextField, Typography, useTheme } from '@mui/material';
import { ComplianceStatus, CustomerTraining, CustomerTrainingMetadata, RefItem, TeamMember, Training, UserTraining, UserTrainingMetadata } from '../../../types';
import './Training.css';
import { ErrorNotify } from '../../../components/Notify.styled';
import ComplianceDonut from '../../../components/ComplianceDonut';
import ComplianceAlertBox from '../../../components/ComplianceAlertBox';
import ComplianceAlertMessage from '../../../components/ComplianceAlertMessage';
import { StyledTableCell, StyledTableRow } from '../../../components/Table.styled';
import CompliancePlip from '../../../components/CompliancePlip';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { selectChildCustomerTeamMembers, selectChildPracticeDetails } from '../../../redux/childCustomer';

interface FormattedTraining {
  'Team Member': string;
  'Training Name': string;
  'Due Date': string;
  'Completion Date'?: string;
  Status: string;
  'CE Credits': string | number;
}

interface TeamMemberFilterOption extends TeamMember {
  name: string
}

interface CourseFilterOption {
  category: string;
  name: string;
  year?: number;
}

interface ErrorMessage {
  data?: {
    error?: {
      message?: string;
    }
  }
}

interface CertificateResult {
  courseid: string;
  coursename: string;
  certificateid: string;
  certificatename: string;
  issued: boolean;
  date: string;
  score: string;
  code: string;
  pdf: string;
  userId: number;
  email: string;
  success: boolean;
}

interface CertificateResults {
  results: CertificateResult[];
}

interface TrainingSummary {
  userId: string | number;
  userName: string;
  overdue: number;
  notStarted: number;
  inProgress: number;
  completed: number;
  complianceStatus: number;
}

interface AssignPayload extends TeamMember {
  training: Training[] | Training;
}

const DefaultCourseFilter = {
  allCourses: { name: 'All Courses', category: 'All Courses' },
  noActiveCourses: { name: 'No Active Courses', category: 'No Courses' },
  noInactiveCourses: { name: 'No Inactive Courses', category: 'No Courses' }
}

const DefaultTeamMemberFilter = { name: 'All Team Members' } as TeamMemberFilterOption;

const DefaultYearFilter = 'All Years';

const UncategorizedCourse = 'Uncategorized';

const PracticeTraining = () => {
  const [allCustomerTrainings, setAllCustomerTrainings] = useState<CustomerTraining[]>([]);
  const [activeCustomerTrainings, setActiveCustomerTrainings] = useState<CustomerTraining[]>([]);
  const [allUserTrainings, setAllUserTrainings] = useState<UserTraining[]>([]);
  const [inactiveCustomerTrainings, setInactiveCustomerTrainings] = useState<CustomerTraining[]>([]);
  const [customerTrainingMetadata, setCustomerTrainingMetadata] = useState<Partial<CustomerTrainingMetadata>>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [userTrainingMetadata, setUserTrainingMetadata] = useState<UserTrainingMetadata[]>([]);
  const [activeCourseFilterOptions, setActiveCourseFilterOptions] = useState<CourseFilterOption[]>([]);
  const [inactiveCourseFilterOptions, setInactiveCourseFilterOptions] = useState<CourseFilterOption[]>([]);
  const [activeCourseFilter, setActiveCourseFilter] = useState<CourseFilterOption>(DefaultCourseFilter.noActiveCourses);
  const [inactiveCourseFilter, setInactiveCourseFilter] = useState<CourseFilterOption>(DefaultCourseFilter.noActiveCourses);
  const [selectedActiveTeamMember, setSelectedActiveTeamMember] = useState<TeamMemberFilterOption>(DefaultTeamMemberFilter);
  const [selectedInactiveTeamMember, setSelectedInactiveTeamMember] = useState<TeamMemberFilterOption>(DefaultTeamMemberFilter);
  const [yearFilterOptions, setYearFilterOptions] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>(DefaultYearFilter);
  const [loadingTrainingId, setLoadingTrainingId] = useState<number | null>(null);
  const [trainingSummary, setTrainingSummary] = useState<TrainingSummary[]>([]);
  const [assignForm, setAssignForm] = useState({ show: false, assign: false });
  const [coursesToAssignOrUnassign, setCoursesToAssignOrUnassign] = useState<CustomerTraining[]>([]);
  const [selectedUsersToAssignOrUnassign, setSelectedUsersToAssignOrUnassign] = useState<TeamMember[]>([]);
  const [openSuccessMessage, setOpenSuccessMessage] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [usersEligibleForAssign, setUsersEligibleForAssign] = useState<TeamMember[]>([]);
  const [inactiveTabTeamMembers, setInactiveTabTeamMembers] = useState<TeamMember[]>([]);
  const customerNumber = useAppSelector(selectCurrentCustomerNumber);
  const customerTeamMembers = useAppSelector(selectTeamMembers);
  const childCustomerTeamMembers = useAppSelector(selectChildCustomerTeamMembers);
  const practiceDetails = useAppSelector(selectPracticeDetails);
  const childPracticeDetails = useAppSelector(selectChildPracticeDetails);
  const currentUser = useAppSelector(selectCurrentUser);
  const isAdminOrOwner = useAppSelector(selectIsAdminOrOwner);
  const isTeamMember = !isAdminOrOwner;
  const isCorpView = useAppSelector(selectCorporateView);
  const theme = useTheme();
  const pageTabs = [`Active(${R.length(activeCustomerTrainings)})`, `Inactive(${R.length(inactiveCustomerTrainings)})`];

  const teamMembers = isCorpView ? childCustomerTeamMembers : customerTeamMembers;
  const currentPracticeDetails = isCorpView ? childPracticeDetails : practiceDetails;

  useEffect(() => {
    if (customerNumber) {
      getCustomerTrainings();
      getUserTrainings();
    }
  }, [customerNumber]);

  const userTrainings: UserTraining[] = useMemo(() => {
    if (isTeamMember) {
      const userId = Number(currentUser?.login_user_id);
      return allUserTrainings.filter((userTraining: UserTraining) => Number(userTraining?.user_id) === userId);
    }
    return allUserTrainings;
  }, [isTeamMember, allUserTrainings])

  const activeTeamMembers: TeamMember[] = useMemo(() => {
    const activeMembers = teamMembers.filter(teamMember => teamMember.account_active).sort((a, b) => {
      let nameA = getFullNameOrEmail(a).toLowerCase();
      let nameB = getFullNameOrEmail(b).toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
    setUsersEligibleForAssign(activeMembers);
    return activeMembers;
  }, [teamMembers]);

  const activeTeamMembersIndex = useMemo(() => {
    if (activeTeamMembers.length > 0) {
      return R.indexBy(activeTeamMembers, R.prop('login_user_id'))
    }
    return {}
  }, [activeTeamMembers])

  const teamMembersIndex = useMemo(() => {
    if (teamMembers.length > 0) {
      return R.indexBy(teamMembers, R.prop('login_user_id'))
    }
    return {}
  }, [teamMembers])

  const trainingCompliance = useMemo(() => {
    // Only show compliance for current user when access level is teamMember
    const userId = Number(currentUser?.login_user_id);

    if (isTeamMember && userTrainings.length > 0) {
      return userTrainings
        .filter(training => Number(training?.user_id) === userId && training.is_active)
        .map(training => {
          return {
            compliance: {
              status: training.compliance_status,
            }
          }
        })
    }
    // Show compliance for all users trainings when access level is admin or owner
    else if (isAdminOrOwner && userTrainings.length > 0) {
      let compliance: { compliance: { status: number } }[] = [];

      userTrainings
        .filter(training => training.is_active && training?.teamMember?.account_active)
        .forEach(training => {
          compliance.push({
            compliance: {
              status: training.compliance_status
            }
          });
        });
      return compliance;
    }

    return [];
  }, [userTrainings, activeTeamMembers, activeCustomerTrainings])

  const checkLaunchCourse = (training: UserTraining) => {
    if (training.completion_date === null && String(currentUser?.login_user_id) === training.user_id) {
      return true;
    }
    return false;
  };

  const checkViewCertificate = (training: UserTraining) => {
    if (training.completion_date !== null && (isAdminOrOwner || String(currentUser?.login_user_id) === training.user_id)) {
      return true;
    }
    return false;
  };

  const getActiveOrInactiveTrainingList = (active: boolean, trainingList: CustomerTraining[]) => {
    const trainingYears = {} as { [Identifier: number]: number };
    const teamMembersWithTrainings: { [Identifier: string]: TeamMember } = {};
    const trainingIndex = R.indexBy(
      R.map(trainingList, (customerTraining) => {
        const expirationDate = new Date(customerTraining.expiration_date);
        const formattedExpirationDate = format(expirationDate, 'P');
        const expiresIn = differenceInCalendarDays(expirationDate, new Date());
        const displayName = `${customerTraining.training.name} (${customerTraining.training.year})`;
        if (!active && !trainingYears[customerTraining.training.year]) trainingYears[customerTraining.training.year] = 1;
        const refItem = customerTraining.training.ref_item ?? { subcategory: UncategorizedCourse } as RefItem;
        if (!refItem.subcategory) refItem.subcategory = UncategorizedCourse;
        customerTraining.training.ref_item = refItem;
        return { ...customerTraining.training, expiresIn, formattedExpirationDate, displayName, is_active: customerTraining.is_active };
      }),
      R.prop('course_id')
    );

    const memberTrainingSummary: { [Identifier: string]: TrainingSummary } = {};
    if (active) {
      activeTeamMembers.forEach(member => {
        memberTrainingSummary[member.login_user_id] = {
          userId: member.login_user_id,
          userName: getFullNameOrEmail(member),
          overdue: 0,
          notStarted: 0,
          inProgress: 0,
          completed: 0,
          complianceStatus: 4
        }
      })
    }

    userTrainings.forEach(training => {
      training.teamMember = teamMembersIndex[training.user_id];
      if (training.teamMember?.account_active) {
        if (trainingIndex[training.course_id]) {
          if (isAdminOrOwner || (isTeamMember && Number(training.user_id) === Number(currentUser?.login_user_id))) {
            if (trainingIndex[training.course_id]?.userTrainings) {
              trainingIndex[training.course_id].userTrainings?.push(training);
            } else {
              trainingIndex[training.course_id].userTrainings = [training];
            }
          }

          if (training.is_active && active) {
            let memberSummary = memberTrainingSummary[training.teamMember?.login_user_id]
            const trainingMetadata = userTrainingMetadata.find(metadata => metadata.user_id === training.teamMember?.login_user_id);
            memberSummary = {
              ...memberSummary,
              overdue: trainingMetadata?.past_due_count ?? 0,
              notStarted: memberSummary.notStarted ?? 0,
              completed: trainingMetadata?.completed_count ?? 0,
              complianceStatus: trainingMetadata?.compliance.compliance_status ?? 4
            }

            if (training.compliance_status === 2) {
              if (training.enrollment_date === null) {
                memberSummary.notStarted++;
              } else {
                memberSummary.inProgress++;
              }
            }
            memberTrainingSummary[training.teamMember?.login_user_id] = memberSummary;
          }
        }
        if (trainingIndex[training.course_id] && !trainingIndex[training.course_id].is_active && !active && !teamMembersWithTrainings[training.user_id] && !training.is_active) {
          teamMembersWithTrainings[training.user_id] = training.teamMember;
        }
      }
    })

    const summary = Object.keys(memberTrainingSummary).map(userId => memberTrainingSummary[userId]);

    const filterOptions = R.pipe(
      trainingList,
      R.map(t => ({ category: t.training.ref_item?.subcategory ?? UncategorizedCourse, name: t.training.name, year: t.training.year })),
      R.sort((a, b) => {
        if (a.category === b.category) {
          return a.name > b.name ? 1 : -1;
        }
        return a?.category > b?.category ? 1 : -1;
      })
    );

    return {
      filterOptions,
      summary,
      trainingIndex,
      trainingYears,
      teamMembersWithTrainings,
      trainingList: R.pipe(
        trainingIndex,
        R.values,
        R.groupBy(training => training?.ref_item?.subcategory),
        R.toPairs,
        R.map(([category, trainingList]) => ({ category, trainingList })),
        R.sortBy(R.prop('category'))
      )
    }
  }

  const activeCustomerTrainingList = useMemo(() => {
    const activeTrainings = R.filter(allCustomerTrainings, (customerTraining: CustomerTraining) => {
      if (isTeamMember) return customerTraining.is_active && userTrainings.find(ut => ut.is_active && Number(ut.course_id) === Number(customerTraining.training.course_id)) !== undefined;
      return customerTraining.is_active;
    });

    setActiveCustomerTrainings(activeTrainings);
    const activeCustomerTrainingList = structuredClone(activeTrainings);
    if (activeCustomerTrainingList.length > 0) {
      const activeTrainings = getActiveOrInactiveTrainingList(true, activeCustomerTrainingList);
      const filterOptions = activeTrainings?.filterOptions ?? [];
      const summary: TrainingSummary[] = activeTrainings?.summary ?? [];
      const trainingList = activeTrainings?.trainingList ?? [];

      setTrainingSummary(summary);

      const defaultCourse = filterOptions.length > 0 ? DefaultCourseFilter.allCourses : DefaultCourseFilter.noActiveCourses;
      setActiveCourseFilter(defaultCourse);
      setActiveCourseFilterOptions(filterOptions);

      return trainingList;
    }

    return [];
  }, [allCustomerTrainings, userTrainings, activeTeamMembers])

  const inactiveCustomerTrainingList = useMemo(() => {
    const inactiveTrainings = R.filter(allCustomerTrainings, (customerTraining: CustomerTraining) => {
      if (isTeamMember) return !customerTraining.is_active && userTrainings.find(ut => Number(ut.course_id) === Number(customerTraining.training.course_id)) !== undefined;
      return !customerTraining.is_active;
    });
    setInactiveCustomerTrainings(inactiveTrainings);
    const inactiveCustomerTrainingList = structuredClone(inactiveTrainings);
    if (inactiveCustomerTrainingList.length > 0) {
      const inactiveTrainings = getActiveOrInactiveTrainingList(false, inactiveCustomerTrainingList);
      const filterOptions = inactiveTrainings?.filterOptions ?? [];
      const trainingList = inactiveTrainings?.trainingList ?? [];
      const trainingYears = inactiveTrainings?.trainingYears ?? {};
      const teamMembersWithTrainings = inactiveTrainings?.teamMembersWithTrainings ?? {};

      const defaultCourse = filterOptions.length > 0 ? DefaultCourseFilter.allCourses : DefaultCourseFilter.noInactiveCourses;
      setInactiveCourseFilter(defaultCourse);
      setInactiveCourseFilterOptions(R.uniqBy(filterOptions, R.prop('name')));
      setYearFilterOptions(Object.keys(trainingYears));
      setInactiveTabTeamMembers(R.values(teamMembersWithTrainings).sort((a, b) => getFullNameOrEmail(a).toLowerCase() < getFullNameOrEmail(b).toLowerCase() ? -1 : 1));

      return trainingList;
    }

    return [];
  }, [allCustomerTrainings, userTrainings, teamMembers])

  const overallTrainingCompliance = useMemo(() => {
    // Only show overall compliance for current user when access level is teamMember
    if (isTeamMember && userTrainingMetadata.length > 0) {
      const userMetadata = userTrainingMetadata.find(user => Number(user.user_id) === Number(currentUser?.login_user_id));
      if (userMetadata) {
        return {
          compliance_status: userMetadata?.compliance?.compliance_status ?? ComplianceStatus.Unassigned,
          message: userMetadata?.compliance?.message_long ?? ''
        }
      }
      return {
        compliance_status: ComplianceStatus.Unassigned,
        message: ''
      }
    }
    // Show overall compliance for practice when access level is owner or admin
    else if (isAdminOrOwner && Object.keys(customerTrainingMetadata).length > 0) {
      return {
        compliance_status: customerTrainingMetadata?.training_compliance_status ?? ComplianceStatus.Unassigned,
        message: customerTrainingMetadata?.compliance?.message_long ?? ''
      }
    }

    return {
      compliance_status: ComplianceStatus.Unassigned,
      message: ''
    }
  }, [customerTrainingMetadata, userTrainingMetadata, userTrainings])

  const getCustomerTrainings = async () => {
    const query = {
      include: [
        {
          relation: 'customerTraining',
          scope: {
            include: {
              relation: 'training',
              scope: {
                include: {
                  relation: 'ref_item'
                }
              }
            }
          }
        },
        {
          relation: 'user_training_metadata',
          scope: {
            include: {
              relation: 'compliance'
            }
          }
        },
        {
          relation: 'customer_training_metadata',
          scope: {
            include: {
              relation: 'compliance'
            }
          }
        }
      ]
    };

    try {
      const response = await axios.get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter=${JSON.stringify(query)}`);
      setAllCustomerTrainings(response?.data?.customerTraining);
      setCustomerTrainingMetadata(response?.data?.customer_training_metadata);
      setUserTrainingMetadata(response?.data?.user_training_metadata);
    } catch (error) {
      console.error(error);
      handleOpenSnackbar('There was an issue retrieving the customer trainings');
    }
  };

  const getUserTrainings = async () => {
    const query = {
      where: {
        customer_number: customerNumber,
        deleted_date: null
      }
    };

    try {
      const response = await axios.get(`${constants.ONTRAQ_API_URL}/api/UsersTrainings?filter=${JSON.stringify(query)}`);
      return setAllUserTrainings(response.data);
    } catch (error) {
      console.error(error);
      handleOpenSnackbar('There was an issue retrieving the user trainings');
    }
  };

  const handleCloseSnackbar = () => {
    setOpenAlert(false);
  }

  const handleOpenSnackbar = (message: string) => {
    setErrorMessage(message);
    setOpenAlert(true);
  }

  const handleDownloadReport = () => {
    const { city, customer_number, doctor_name, name, state, street, zip } = currentPracticeDetails;
    const wb = XLSX.utils.book_new();
    const fileName = 'Training By Practice';
    const customerData = [
      {
        A: name
      },
      {
        A: doctor_name
      },
      {
        A: street
      },
      {
        A: `${city}, ${state} ${zip}`
      },
      {
        A: `Customer #: ${customer_number}`
      }
    ];
    const customerTrainings = tabIndex === 0 ? activeCustomerTrainings : inactiveCustomerTrainings;

    const ws = XLSX.utils.json_to_sheet(customerData, { skipHeader: true });

    if (teamMembers.length > 0) {
      let reportStartYear: Date;
      let reportEndYear: Date = new Date();
      if (tabIndex === 0) {
        reportStartYear = subYears(reportEndYear, 1);
      } else {
        reportStartYear = new Date(`01/01/${yearFilterOptions[0]}`);
      }
      const trainingIndex = R.indexBy(
        R.filter(customerTrainings, (t) => tabIndex === 0 ? t.is_active : !t.is_active)
          .map((customerTraining) => customerTraining.training),
        R.prop('course_id')
      );

      let formattedTrainings: FormattedTraining[] = [];

      userTrainings.forEach((training) => {
        const startDate = new Date(training.start_date);

        if ((tabIndex === 0 && isAfter(startDate, reportStartYear)) || (tabIndex === 1)) {
          if (trainingIndex[training.course_id] &&
            (training.is_active && tabIndex === 0 && teamMembersIndex[training.user_id]?.account_active) ||
            (tabIndex === 1 && !training.is_active)) {
            const userName = getFullNameOrEmail(teamMembersIndex[training.user_id]);
            const formattedTraining = {
              'Team Member': R.isEmpty(userName) ? 'No Name' : userName,
              'Training Name': `${trainingIndex[training.course_id]?.name} ${trainingIndex[training.course_id].year}` ?? 'Unknown',
              'Due Date': training?.due_date ? format(new Date(training.due_date.slice(0, 10)), 'P') : '',
              'Completion Date': training?.completion_date ? format(new Date(training.completion_date.slice(0, 10)), 'P') : '',
              'Status': training?.completion_date ? (tabIndex === 0 ? 'pass' : 'complete') : 'incomplete',
              'CE Credits': trainingIndex[training.course_id]?.ce_credits ?? 'N/A'
            };

            formattedTrainings = [...formattedTrainings, formattedTraining];
          }
        }
      });

      if (formattedTrainings.length > 0) {
        formattedTrainings = R.sortBy(formattedTrainings, (training) => training['Team Member']);

        XLSX.utils.sheet_add_json(
          ws,
          [
            {
              A: `${format(reportStartYear, 'P')} - ${format(reportEndYear, 'P')} ${tabIndex === 0 ? '(Shows 1 full calendar year for all downloads)' : ''}`
            }
          ],
          { skipHeader: true, origin: 'A8' }
        );
        XLSX.utils.sheet_add_json(ws, formattedTrainings, {
          origin: 'A10'
        });
      } else {
        XLSX.utils.sheet_add_json(ws, [{ A: tabIndex === 0 ? 'There are no trainings for this practice' : 'There are no inactive trainings for this practice' }], { skipHeader: true, origin: -1 });
      }
    } else {
      XLSX.utils.sheet_add_json(ws, [{ A: 'There are no team members in this practice' }], { skipHeader: true, origin: -1 });
    }

    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const handleLaunchCourse = (training: UserTraining) => {
    const data = {
      user: training.teamMember,
      training,
    };

    axios.post(`${constants.ONTRAQ_API_URL}/api/UsersTrainings/launchCourse`, { data })
      .then((res) => {
        const courseLink = res?.data?.results.url;
        if (courseLink) {
          window.open(courseLink, '_blank');
        }
      })
      .catch(error => {
        console.error(error);
        const err = error as ErrorMessage;
        const errorMessage = err?.data?.error?.message ?? 'There was an error launching your course, please try again later';
        handleOpenSnackbar(errorMessage);
      })
  }

  const handleDownloadCertificate = (training: UserTraining) => {
    const data = {
      users: [training.teamMember]
    };

    setLoadingTrainingId(training.id);

    axios.post(`${constants.ONTRAQ_API_URL}/api/UsersTrainings/generateCertificate`, { data })
      .then((res) => {
        setLoadingTrainingId(null);
        const { results } = res.data as CertificateResults;
        const certificate = results.filter(certificate => {
          return certificate.courseid === training.course_id;
        })

        if (certificate[0].success === true) {
          const pdf = certificate[0].pdf;

          window.open(pdf, '_blank');
        }
      })
      .catch(error => {
        setLoadingTrainingId(null);
        console.error(error);
        const err = error as ErrorMessage;
        const errorMessage = err?.data?.error?.message ?? 'There was an error downloading your certificate, please try again later';
        handleOpenSnackbar(errorMessage);
      })
  }

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const getTrainingStatus = (training: UserTraining) => {
    switch (training.compliance_status) {
      case 1:
        return 'Completed';
      case 2:
        return training.enrollment_date ? 'In Progress' : 'Not Started';
      case 3:
        return 'Over Due';
      default:
        return tabIndex === 1 && training.completion_date ? 'Completed' : 'Inactive';
    }
  }

  const filteredUserTrainings = (training: Training, selectedMember: TeamMemberFilterOption) => {
    const trainings = training?.userTrainings && training?.userTrainings?.length > 0 ? training.userTrainings : [];
    if (selectedMember.name === DefaultTeamMemberFilter.name) return trainings;
    return trainings.filter(userTraining => {
      return userTraining.user_id === selectedMember.login_user_id
    });
  }

  const assignOrUnassign = async () => {
    setAssignForm({ ...assignForm, show: false });
    const payload: AssignPayload[] = [];
    selectedUsersToAssignOrUnassign.forEach(member => {
      payload.push({ ...member, training: coursesToAssignOrUnassign.map(course => course.training) });
    });

    await axios.post(`${constants.ONTRAQ_API_URL}/api/UsersTrainings/${assignForm.assign ? 'assignTraining' : 'unassignTraining'}`, {
      data: {
        users: payload
      }
    }).then(response => {
      setCoursesToAssignOrUnassign([]);
      setSelectedUsersToAssignOrUnassign([]);
      setSuccessMessage(response.data.results.message);
      setOpenSuccessMessage(true);
      getCustomerTrainings();
      getUserTrainings();
    }).catch(e => {
      setCoursesToAssignOrUnassign([]);
      setSelectedUsersToAssignOrUnassign([]);
      setErrorMessage(e.response.data.error.message);
      setOpenAlert(true);
    })
  }

  const handleCloseAssignForm = () => {
    setAssignForm({ show: false, assign: false });
    setCoursesToAssignOrUnassign([]);
    setSelectedUsersToAssignOrUnassign([]);
    setUsersEligibleForAssign(activeTeamMembers);
  }

  const handleChangeCourseToAssign = (selectedTrainings: CustomerTraining[]) => {
    setCoursesToAssignOrUnassign(selectedTrainings);
    if (selectedTrainings.length > 1 || selectedTrainings.length === 0) {
      setUsersEligibleForAssign(activeTeamMembers);
    } else {
      const eligibleUsers = activeTeamMembers.filter(member => {
        const trainingAssigned = R.intersectionWith(
          userTrainings,
          selectedTrainings,
          (ut, t) => Number(t.training.course_id) === Number(ut.course_id) && Number(ut.teamMember?.login_user_id) === Number(member.login_user_id)
        ).length > 0
        return assignForm.assign ? !trainingAssigned : trainingAssigned;
      });
      setUsersEligibleForAssign(eligibleUsers);
      setSelectedUsersToAssignOrUnassign(
        R.intersectionWith(
          selectedUsersToAssignOrUnassign,
          eligibleUsers,
          (su, eu) => Number(su.login_user_id) === Number(eu.login_user_id)
        )
      );
    }
  }

  const TabContent = () => {
    const trainingList = tabIndex === 0 ? activeCustomerTrainingList : inactiveCustomerTrainingList;
    const courseFilterOptions = tabIndex === 0 ? activeCourseFilterOptions : inactiveCourseFilterOptions;
    const courseFilter = tabIndex === 0 ? activeCourseFilter : inactiveCourseFilter;
    const setCourseFilter = tabIndex === 0 ? setActiveCourseFilter : setInactiveCourseFilter;
    const teamMemberFilterOptions = tabIndex === 0 ? activeTeamMembers : inactiveTabTeamMembers;
    const selectedTeamMember = tabIndex === 0 ? selectedActiveTeamMember : selectedInactiveTeamMember;
    const setSelectedTeamMember = tabIndex === 0 ? setSelectedActiveTeamMember : setSelectedInactiveTeamMember;
    return (
      <>
        <Box className="flex flex-end" style={{ marginBottom: 50 }}>
          <Button onClick={handleDownloadReport} variant="contained" style={{ fontSize: '13px' }}>
            Download Report
          </Button>
        </Box>
        <Box className="filter-container flex flex-space-between" style={{ padding: 0 }}>
          <Box className="flex">
            <Autocomplete
              disabled={trainingList.length === 0}
              clearOnEscape
              disableClearable={courseFilter.name === DefaultCourseFilter.allCourses.name}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  setCourseFilter(newValue);
                } else {
                  setCourseFilter(DefaultCourseFilter.allCourses);
                }
              }}
              isOptionEqualToValue={(option, value) => option.category === option.name || option.name === value.name}
              options={courseFilterOptions}
              groupBy={(option) => option?.category}
              getOptionLabel={(option) => `${option.name} ${option.year ?? ''}`}
              renderInput={(params) => <TextField {...params} />}
              value={courseFilter}
              renderGroup={(params) => (
                <div key={params.key}>
                  {Number(params.key) == 0 ? <li>
                    <div
                      className={`filter-option-group${courseFilter.name === DefaultCourseFilter.allCourses.name ? ' selected' : ''}`}
                      onClick={() => {
                        setCourseFilter(DefaultCourseFilter.allCourses);
                      }}
                    >
                      {DefaultCourseFilter.allCourses.name}
                    </div>
                  </li> : null}
                  <li>
                    <div
                      className={`filter-option-group${courseFilter.name === params.group ? ' selected' : ''}`}
                      onClick={() => {
                        setCourseFilter({ name: params.group, category: params.group });
                      }}
                    >
                      {params.group}
                    </div>
                    <ul className='filter-options-list' style={{ padding: 0 }}>{params.children}</ul>
                  </li>
                </div>
              )}
              style={{ width: '200px' }}
              size="small"
              className="autocomplete-filter"
            />
            {isAdminOrOwner ?
              <Autocomplete
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedTeamMember(newValue);
                  } else {
                    setSelectedTeamMember(DefaultTeamMemberFilter);
                  }
                }}
                disableClearable={selectedTeamMember.name === DefaultTeamMemberFilter.name}
                options={[DefaultTeamMemberFilter, ...teamMemberFilterOptions.map(teamMember => ({ ...teamMember, name: getFullNameOrEmail(teamMember) }))]}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    className={`filter-option${selectedTeamMember?.name === option.name ? ' selected' : ''}`}
                  >
                    {option.name}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} />}
                value={selectedTeamMember}
                size="small"
                className="autocomplete-filter"
                style={{ width: '200px' }}
              />
              : null}
            {tabIndex === 1 ? <Autocomplete
              disableClearable={selectedYear === DefaultYearFilter}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedYear(newValue);
                } else {
                  setSelectedYear(DefaultYearFilter);
                }
              }}
              options={[DefaultYearFilter, ...yearFilterOptions]}
              renderOption={(props, option) => (
                <li
                  {...props}
                  className={`filter-option${selectedYear === option ? ' selected' : ''}`}
                >
                  {option}
                </li>
              )}
              renderInput={(params) => <TextField {...params} />}
              value={selectedYear}
              size="small"
              className="autocomplete-filter"
            /> : null}
          </Box>
          {tabIndex === 0 && isAdminOrOwner ? <Box className='assign-button-container'>
            <Button
              startIcon={<AddIcon />}
              disabled={assignForm.show}
              variant="contained"
              style={{ marginRight: 10 }}
              onClick={() => setAssignForm({ show: true, assign: true })}
            >
              Assign Training
            </Button>
            <Button
              startIcon={<RemoveIcon />}
              disabled={assignForm.show}
              variant="contained"
              onClick={() => setAssignForm({ show: true, assign: false })}
            >
              Unassign Training
            </Button>
          </Box> : null}
        </Box>
        {assignForm.show && tabIndex === 0 && isAdminOrOwner ?
          <Box style={{ border: '1px solid grey', padding: 10, marginTop: 10, marginBottom: 10 }}>
            {assignForm.assign ?
              <Typography style={{ fontSize: '13px' }} className="assign-form-text">This will make the training required for your practice. Using this will enable a compliance status that will help keep your team members on track. Team members can only take trainings that have been assigned to them.</Typography>
              : <Typography style={{ fontSize: '13px' }} className="assign-form-text">Unassign Training allows you to remove a required course from one of your team members. Please Select the course and team member.</Typography>
            }
            <form style={{ marginTop: 10, marginBottom: 10 }}>
              <Box className="flex">
                <FormControl>
                  <Autocomplete
                    multiple
                    options={activeCustomerTrainings.sort((a, b) => `${a.training.name} ${a.training.year}`.toLowerCase() < `${b.training.name} ${b.training.year}`.toLowerCase() ? -1 : 1)}
                    value={coursesToAssignOrUnassign}
                    getOptionLabel={(option) => `${option.training.name} ${option.training.year}`}
                    onChange={(_, value) => {
                      handleChangeCourseToAssign(value as CustomerTraining[]);
                    }}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        label={"Course *"}
                        placeholder="Select Course(s)"
                      />
                    }
                    sx={{ width: '500px' }}
                    size="small"
                    className="autocomplete-filter"
                  />
                </FormControl>
                <Button
                  disabled={coursesToAssignOrUnassign.length === activeCustomerTrainings.length}
                  size="small"
                  style={{ marginLeft: '10px', height: '37px', marginTop: 'auto', fontSize: '13px' }}
                  variant="outlined"
                  onClick={() => setCoursesToAssignOrUnassign(activeCustomerTrainings)}
                >{assignForm.assign ? 'Assign' : 'Unassign'} All Courses</Button>
              </Box>
              <Box className="flex" sx={{ marginTop: '10px' }}>
                <FormControl>
                  <Autocomplete
                    multiple
                    options={usersEligibleForAssign}
                    value={selectedUsersToAssignOrUnassign}
                    getOptionLabel={(option) => getFullNameOrEmail(option)}
                    onChange={(event, value) => setSelectedUsersToAssignOrUnassign(value)}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        label={assignForm.assign ? "Assign To *" : "Unassign From *"}
                        placeholder="Select Member(s)"
                      />
                    }
                    sx={{ width: '500px' }}
                    size="small"
                    className="autocomplete-filter"
                  />
                </FormControl>
                <Button
                  disabled={selectedUsersToAssignOrUnassign.length === activeTeamMembers.length}
                  size="small"
                  style={{ marginLeft: '10px', height: '37px', marginTop: 'auto', fontSize: '13px' }}
                  variant="outlined"
                  onClick={() => setSelectedUsersToAssignOrUnassign(usersEligibleForAssign)}
                >{assignForm.assign ? 'Assign to' : 'Unassign from'} All</Button>
              </Box>
              <Box className="flex flex-end">
                <Button
                  disabled={selectedUsersToAssignOrUnassign.length === 0 || coursesToAssignOrUnassign.length === 0}
                  variant="contained"
                  onClick={assignOrUnassign}
                >Save</Button>
                <Button onClick={handleCloseAssignForm}>Cancel</Button>
              </Box>
            </form>
          </Box>
          : null}
        {tabIndex === 0 && isAdminOrOwner && trainingList.length > 0 ?
          <Box className="m-t-16">
            <Accordion disableGutters sx={{ mb: 1 }} defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className='header-text'>Training Summary</div>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>User</StyledTableCell>
                      <StyledTableCell>Overdue</StyledTableCell>
                      <StyledTableCell>Not Started</StyledTableCell>
                      <StyledTableCell>In Progress</StyledTableCell>
                      <StyledTableCell>Completed</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trainingSummary.filter(user => selectedTeamMember.name === DefaultTeamMemberFilter.name || user.userName === selectedTeamMember.name).toSorted((a, b) => {
                      if (a.userName.toLowerCase() < b.userName.toLowerCase()) return -1;
                      if (a.userName.toLowerCase() > b.userName.toLowerCase()) return 1;
                      return 0;
                    }).map(user => (
                      <StyledTableRow key={user.userId}>
                        <StyledTableCell><CompliancePlip className="m-r-8" status={user.complianceStatus} /></StyledTableCell>
                        <StyledTableCell>{user.userName}</StyledTableCell>
                        <StyledTableCell>{user.overdue}</StyledTableCell>
                        <StyledTableCell>{user.notStarted}</StyledTableCell>
                        <StyledTableCell>{user.inProgress}</StyledTableCell>
                        <StyledTableCell>{user.completed}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          </Box>
          : null}
        <Box className="course-container">
          <div className="training-header">Training Courses</div>
          <div className='p-16'>
            {trainingList.length > 0 ? trainingList
              .filter(({ category, trainingList }) => {
                return trainingList.some(training => {
                  return courseFilter.category === DefaultCourseFilter.allCourses.name || category === courseFilter.category;
                });
              })
              .map(({ category, trainingList }) => (
                <div key={category}>
                  <Accordion disableGutters sx={{ mb: 1 }} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <div className='category-header'>{category}</div>
                    </AccordionSummary>
                    <AccordionDetails className='category-body' sx={{ paddingTop: '16px' }}>
                      {trainingList
                        .filter(training =>
                          (tabIndex === 0 || (selectedYear === DefaultYearFilter || Number(selectedYear) === (training.year))) &&
                          (courseFilter.category === DefaultCourseFilter.allCourses.name || courseFilter.name === training.name || activeCourseFilter.name === category)
                        )
                        .map((training) => (
                          <Accordion disableGutters key={training.id} sx={{ mb: 1 }} defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Box className='flex'>
                                <Typography>{training.displayName}</Typography>
                              </Box>
                              {tabIndex === 0 && training.expiresIn <= 14 ?
                                <Box className='flex flex-end'>
                                  <Typography
                                    fontSize='13px'
                                    color='red'
                                    style={{ position: 'absolute', right: 50 }}
                                  >
                                    {training.expiresIn} day(s) remaining. course will expire on {training.formattedExpirationDate}
                                  </Typography>
                                </Box>
                                : null}
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: 0 }}>
                              {filteredUserTrainings(training, selectedTeamMember).length > 0 ? (
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell></StyledTableCell>
                                      <StyledTableCell>User</StyledTableCell>
                                      <StyledTableCell>Status</StyledTableCell>
                                      <StyledTableCell>CE Credits</StyledTableCell>
                                      <StyledTableCell>Due Date</StyledTableCell>
                                      <StyledTableCell>Completion Date</StyledTableCell>
                                      <StyledTableCell>Certificate</StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {filteredUserTrainings(training, selectedTeamMember).sort((a, b) => {
                                      let nameA = getFullNameOrEmail(a?.teamMember).toLowerCase();
                                      let nameB = getFullNameOrEmail(b?.teamMember).toLowerCase();
                                      if (nameA < nameB) {
                                        return -1;
                                      }
                                      if (nameA > nameB) {
                                        return 1;
                                      }
                                      return 0;
                                    }).map(userTraining => {
                                      const trainingStatus = getTrainingStatus(userTraining);
                                      return (
                                        <StyledTableRow key={userTraining.id}>
                                          <StyledTableCell><CompliancePlip className="m-r-8" status={userTraining.compliance_status} /></StyledTableCell>
                                          <StyledTableCell>{getFullNameOrEmail(userTraining?.teamMember)}</StyledTableCell>
                                          <StyledTableCell>{trainingStatus !== 'Completed' && tabIndex === 1 ? 'Incomplete' : trainingStatus}</StyledTableCell>
                                          <StyledTableCell>{training.ce_credits}</StyledTableCell>
                                          <StyledTableCell>{userTraining?.due_date ? format(new Date(userTraining.due_date), 'P') : ''}</StyledTableCell>
                                          <StyledTableCell>{userTraining?.completion_date ? format(new Date(userTraining.completion_date), 'P') : ''}</StyledTableCell>
                                          <StyledTableCell>
                                            {tabIndex === 0 && checkLaunchCourse(userTraining) && (
                                              <Button onClick={() => handleLaunchCourse(userTraining)} variant="contained" size="small">
                                                Launch Course
                                              </Button>
                                            )}
                                            {checkViewCertificate(userTraining) && (
                                              <Button onClick={() => handleDownloadCertificate(userTraining)} variant="contained" size="small" disabled={loadingTrainingId === userTraining.id}>
                                                {loadingTrainingId === userTraining.id ? 'Loading' : 'View'}
                                              </Button>
                                            )}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      )
                                    })}
                                  </TableBody>
                                </Table>
                              )
                                : <Typography style={{ padding: 15, borderTop: '1px solid rgba(0, 0, 0, 0.12)', fontSize: '13px' }}>This training has not been assigned to any team members. {tabIndex === 0 ? 'Please use the "Assign Training" button to assign training' : ''}</Typography>
                              }
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))
              : <Typography>{`There are no ${tabIndex === 0 ? 'active' : 'inactive'} trainings for this practice.`}</Typography>
            }
          </div>
        </Box>
      </>
    )
  }

  return (
    <>
      <ErrorNotify
        open={openAlert}
        onClose={handleCloseSnackbar}
      >
        {errorMessage}
      </ErrorNotify>
      <Snackbar
        open={openSuccessMessage}
        onClose={() => setOpenSuccessMessage(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert>{successMessage}</Alert>
      </Snackbar>
      <PageHeader iconName={'graduation-cap'} headerName={'Training'} />
      <Box sx={{ width: '100%', color: theme.palette.color50555b.main }} className="training-container">
        <Box sx={{ width: '25%' }} className="compliance-column">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="status-header">
            Status
          </Box>
          <Stack className={'m-t-16'} spacing={1.5}>
            <ComplianceAlertBox compliance_status={overallTrainingCompliance.compliance_status} data-testid="compliance-alert-box"></ComplianceAlertBox>
            <ComplianceAlertMessage
              compliance_status={overallTrainingCompliance.compliance_status}
              message={overallTrainingCompliance.message}
              data-testid="compliance-alert-message"
            ></ComplianceAlertMessage>
            <ComplianceDonut image="chart-training" label="Training" values={trainingCompliance} inactiveLabel={'Unassigned'} />
          </Stack>
        </Box>
        <Box sx={{ width: '75%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="flex flex-end m-b-16">
            <Tabs value={tabIndex} onChange={handleTabChange}>
              {pageTabs.length && pageTabs.map((tab, index) => <Tab key={index} label={tab} />)}
            </Tabs>
          </Box>
          <TabContent />
        </Box>
      </Box>
    </>
  );
};

export default PracticeTraining;
